<div class="header font-calibre-20" [ngClass]="{'mobile-menu-open': isMobileMenuOpen}"
  [style.--under-line-color]="underLineColor">
  <div class="left-section">
    <div class="logo">
      <img class="cursor-pointer" src="assets/images/logo.png" alt="logo" (click)="redirectTo('home')">
    </div>
  </div>

  <div class="menu-container">
    <div #menuContainer class="full-width menu-items gap-4" (mouseenter)="onMouseEnterMenu()"
      (mouseleave)="onMouseLeaveMenu()">

      @for(menuItem of allMenuItems; track menuItem; let i = $index) {
      <div class="menu-item cursor-pointer" (click)="navigateTo(menuItem)" (mouseenter)="handleMenuItemHover(i, menuItem)"
        (mouseleave)="handleMenuItemLeave()"
        [ngClass]="{'menu-item-active': currentPage === menuItem.redirectLink || subMenuItemsRedirects[menuItem.title]?.includes(currentPage)}">
        <span>{{ menuItem.title }}</span>
        <div class="underline"></div>

        @if(menuItem.subMenuItem.length && i === selectedSubMenuIndex) {
        <div class="sub-items font-univia-pro-17 text-capitalize" #subItems (mouseenter)="onMouseEnterSubMenu()"
          (mouseleave)="onMouseLeaveSubMenu()">
          @for(subMenu of menuItem.subMenuItem; track subMenu; let first = $first) {
          <div class="sub-item cursor-pointer"
            (click)="handleSubMenuItemClick(menuItem?.redirectLink, subMenu?.fragment, subMenu?.redirectLink, subMenu?.title, subMenu?.underLineColor)">
            {{ subMenu.title }}
            <div class="divider" [style.--under-line-color]="subMenu.underLineColor"
              [class.active]="subMenu.redirectLink === currentPage && (!subMenu.fragment || subMenu.fragment === currentFragment)">
            </div>
          </div>
          }
        </div>
        }

        @if(selectedSubMenuIndex){
        <div class="sub-menu-background full-width" (mouseenter)="onMouseEnterSubMenu()"
          (mouseleave)="onMouseLeaveSubMenu()">
        </div>
        }
      </div>
      }
    </div>
    <div class="menu-overlay" (mouseenter)="onMouseEnterMenu()" (mouseleave)="handleMenuItemLeave()"></div>
  </div>

  <!-- right side -->
  <div class="right-section font-univia-pro-14 text-uppercase gap-50">
    <div class="gap-10 cursor-pointer my-neo-content">
      <a class="inline-flex items-center gap-10 font-univia-pro-14" href="https://my.neo.swiss/" target="_blank">
        <span class="inline-flex  my-neo-link"> MYNEO</span>
        <img src="assets/images/Lock.svg" alt="lock" />
      </a>
    </div>
    <div class="inline-flex justify-between language cursor-pointer" (click)="toggleLanguageDropdown();">
      <div class="dropdown inline-flex gap-10 items-center">
        <div>
          <span>{{selectedLanguage}}</span>
          <div>@if(isLanguageDropdownOpen){
            <div class="dropdown-language font-univia-pro-14"> @for(language of languages; track language){
              <div class="language-item" (click)="changeLanguage(language); toggleLanguageDropdown()">{{ language }}
              </div>
              }
            </div>
            }
          </div>
        </div>
        <img src="assets/images/arrow-down.svg" alt="Dropdown Arrow" />
      </div>
    </div>

    <!-- Burger menu for smaller screens -->
    <div class="smaller-width burger-menu" (click)="toggleMobileMenu();">
      <img src="assets/images/burger-menu.svg" alt="Burger Menu" />
    </div>
  </div>
</div>

@if(selectedSubMenuIndex){
<div class="sub-menu-background full-width" #subMenuBackground>
</div>
}

<div class="underline full-width"
  [ngClass]="{'underline-active': selectedSubMenuIndex, 'underline': !selectedSubMenuIndex}"></div>

<!-- mobile menu -->

<div class="smaller-width menu-items" [ngClass]="{'mobile-menu-open': isMobileMenuOpen}">
  @for(menuItem of allMenuItems; track menuItem; let i = $index){
  <div class="menu-item cursor-pointer" (click)="handleMenuItemClick(i, menuItem)">
    @if (!menuItem?.subMenuItem.length) {
    <div>{{menuItem?.title}}</div>
    }
    @else {
    <div class="arrow">
      <span>{{menuItem?.title}}</span>
      <div>
        <img [src]="selectedSubMenuIndex === i ? 'assets/images/up.svg' : 'assets/images/down.svg'" width="18"
          height="10" alt="Arrow icon">
      </div>
    </div>
    @if(menuItem?.subMenuItem.length && i === selectedSubMenuIndex){
    <div class="sub-items font-univia-pro-17 text-capitalize">
      @for(subMenu of menuItem.subMenuItem; track subMenu; let first = $first) {
      <div class="sub-item cursor-pointer"
        (click)="handleSubMenuItemClick(menuItem?.redirectLink, subMenu?.fragment, subMenu?.redirectLink, subMenu?.title,subMenu?.underLineColor)"
        [style.--under-line-color]="subMenu.underLineColor">
        {{subMenu.title}}
        @if(subMenu.fragment === currentFragment) {
        <div class="divider" [class.divider]="subMenu.redirectLink === currentPage"></div>
        }
      </div>
      }
    </div>
    }
    }
  </div>
  }
</div>